import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { USER_API } from '../../common/common';
import { Logout } from '../../common/Funcation';
import Loader from '../../components/Loader';
import NewsLine from '../../components/NewsLine';
import { notifyError, notifyMessage, postApi } from '../../service';
import LoaderImage from "../../assets/images/loaderajaxbet.gif"
import CricketImage from "../../assets/images/cricket-m.jpeg"



import YoutubeImage from '../../assets/images/casino-icon/youtube.png'
import FImage from '../../assets/images/casino-icon/f.png'
import BImage from '../../assets/images/casino-icon/b.png'
import SImage from '../../assets/images/casino-icon/s.png'
import BottomFooter from '../../components/BottomFooter';
import Footer from '../../components/Footer';

const cookies = new Cookies()


const Sports = () => {
    const navigate = useNavigate()

    const inPlayDetail = useSelector((e) => e.inPlayDetail ? e.inPlayDetail : undefined);
    console.log("inPlayDetail :::: ", inPlayDetail);
    const [pageData, setPageData] = useState(inPlayDetail?.inPlay ? inPlayDetail?.inPlay : {})
    const [tab, setTab] = useState('play')
    const [cricketToggle, setCricketToggle] = useState(false)
    const [soccerToggle, setSoccerToggle] = useState(false)
    const [tennisToggle, setTennisToggle] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch();
    const [isLoadingMain, setIsLoadingMain] = useState(false)
    const [allTurnaments, setAllTurnaments] = useState()



    const [openDropdown, setOpenDropdown] = useState(false);
    const [categoryState, setCategoryState] = useState({
        cricket: false,
        soccer: false,
        tennis: false
    })

    useEffect(() => {
        setIsLoadingMain(true)
        getPageData('play')
        setTimeout(() => {
            dispatch({ type: 'GET_SPORTS_DETAILS', payload: {} });
        }, 1000);
        return () => { }
    }, [])

    useEffect(() => {
        if (JSON.stringify(inPlayDetail) !== '{}' && inPlayDetail !== undefined) {
            // setIsLoadingMain(false)
            setPageData(
                tab === 'play' ? inPlayDetail?.inPlay :
                    tab === 'today' ? inPlayDetail?.today :
                        tab === 'tomorrow' ? inPlayDetail?.tomorrow : {}
            )

        }
    }, [inPlayDetail])





    const getPageData = async (FILTER) => {
        setIsLoadingMain(true)

        let data = {
            api: USER_API.IN_PLAY,
            value: { filter: FILTER }
        }

        await postApi(data).then(function (response) {
            console.log("response :::: ", response);
            setPageData(response.data.data)
            let uniqueTurnaments = []
            if (response?.data?.data) {
                for (const category in response?.data?.data) {
                    const categoryData = response?.data?.data[category];
                    const turnamentArray = categoryData.map(item => item.Turnament);
                    uniqueTurnaments[category] = [...new Set(turnamentArray)];
                }

                setAllTurnaments(uniqueTurnaments)

                let data = { ...uniqueTurnaments }
                console.log("data", data);

                const result = Object.entries(data) // Get an array of key-value pairs
                    .flatMap(([category, values]) => { // Destructure each entry into category and values
                        return values.slice(0, 1).map(value => `${category}_${value}`); // Concatenate category name with each value
                    });

                setActiveAccordions(result)

            }

            if (FILTER === 'play') {
                dispatch({ type: 'SET_IN_PLAY_DETAILS_IN_PLAY', payload: response.data.data });
            } else if (FILTER === 'today') {
                dispatch({ type: 'SET_IN_PLAY_DETAILS_TODAY', payload: response.data.data });
            } else if (FILTER === 'tomorrow') {
                dispatch({ type: 'SET_IN_PLAY_DETAILS_TOMORROW', payload: response.data.data });
            } else {
                dispatch({ type: 'SET_IN_PLAY_DETAILS', payload: undefined });
            }
            setIsLoading(false)
            setIsLoadingMain(false)
        }).catch(err => {
            setIsLoadingMain(false)
            setIsLoading(false)
            console.log(err);
            if (err.response.data.statusCode === 401) {
                Logout()
                // navigate('/login')
            }
        })
    }



    const toggleCategory = (category) => {
        let data = { ...allTurnaments }
        if (categoryState[category]) {
            setCategoryState({ ...categoryState, [category]: false })
            return activeAccordions?.filter(value => !value.startsWith(`${category}_`));
        } else {
            setCategoryState({ ...categoryState, [category]: true })
            let newdata = [...activeAccordions, ...data[category].map(value => `${category}_${value}`)]
            return Array.from(new Set(newdata));
        }
    }

    const handleClick = (category) => {
        const result = toggleCategory(category);
        setActiveAccordions(result);
    }





    const changeTab = (TAB) => {
        setIsLoadingMain(true)
        if (TAB === 'play') {
            setPageData(inPlayDetail?.inPlay)
            setIsLoadingMain(false)
        } else if (TAB === 'today') {
            setPageData(inPlayDetail?.today)
            setIsLoadingMain(false)
        } else if (TAB === 'tomorrow') {
            setPageData(inPlayDetail?.tomorrow)
            setIsLoadingMain(false)
        }
        getPageData(TAB)
        setTab(TAB)
        setCricketToggle(false)
        setSoccerToggle(false)
        setTennisToggle(false)
    }

    //new code 


    const [activeAccordions, setActiveAccordions] = useState();
    const [parlyActive, setParlyActive] = useState(false)
    const [categoryActive, setcategoryActive] = useState('all')
    const [searchActive, setSearchActive] = useState(false)


    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const category = params.get('category');


    useEffect(() => {
        if (category?.length) {
            setcategoryActive(category)
        }
    }, [category])


    useEffect(() => {
        let data = { ...allTurnaments }
        if (categoryActive !== 'all') {
            const result = Object.entries(data) // Get an array of key-value pairs
                .flatMap(([category, values]) => { // Destructure each entry into category and values
                    return values.map(value => `${category}_${value}`); // Concatenate category name with each value
                });
            setActiveAccordions(result);
            setCategoryState({
                cricket: true,
                soccer: true,
                tennis: true
            })
        }
        else {
            const result = Object.entries(data) // Get an array of key-value pairs
                .flatMap(([category, values]) => { // Destructure each entry into category and values
                    return values.slice(0, 1).map(value => `${category}_${value}`); // Concatenate category name with each value
                });

            setActiveAccordions(result)
            setCategoryState({
                cricket: false,
                soccer: false,
                tennis: false
            })
        }
    }, [categoryActive])

    const toggleAccordion = (id) => {
        setActiveAccordions((prevAccordions) =>
            prevAccordions.includes(id)
                ? prevAccordions.filter((accordionId) => accordionId !== id)
                : [...prevAccordions, id]
        );
    };

    // Custom comparator function to sort undefined values to last
    const customSort = (a, b) => {
        if (a === undefined) return 1;
        if (b === undefined) return -1;
        return a - b; // Adjust this line based on your sorting criteria
    };



    return (
        <>
            <NewsLine />
            {console.log("activeAccordions", activeAccordions)}
            <div className='full-wrap bottomfooter sports'>


                <div className="parlay-tab">
                    <form className="d-flex align-items-center">
                        <div className="parlay-checkbox d-flex">
                            <div className="parly-check form-switch" >
                                <input type="checkbox" id='parly' checked={parlyActive} onClick={(e) => setParlyActive(e?.target?.checked)} />
                                <label htmlFor="parly"></label>
                            </div>
                            <span >Parlay</span>
                        </div><div className="parlay-inner-tab d-flex align-items-center">
                            <div><ul className="mb-0 p-0">
                                <li className={`${tab === 'play' ? 'active' : ''} `} onClick={() => changeTab('play')} >In-Play</li>
                                <li className={`${tab === 'today' ? 'active' : ''} `} onClick={() => changeTab('today')}>Today</li>
                                <li className={`${tab === 'tomorrow' ? 'active' : ''} `} onClick={() => changeTab('tomorrow')}>Tomorrow</li>
                                <li className='only_mobile'>
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M908.1 353.1l-253.9-36.9L540.7 86.1c-3.1-6.3-8.2-11.4-14.5-14.5-15.8-7.8-35-1.3-42.9 14.5L369.8 316.2l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3a32.05 32.05 0 0 0 .6 45.3l183.7 179.1-43.4 252.9a31.95 31.95 0 0 0 46.4 33.7L512 754l227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2 17.4-3 29.1-19.5 26.1-36.9l-43.4-252.9 183.7-179.1c5-4.9 8.3-11.3 9.3-18.3 2.7-17.5-9.5-33.7-27-36.3z"></path></svg>

                                </li>
                                <li className='only_mobile'>
                                    <svg onClick={() => setSearchActive(true)} stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" className="ms-auto" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="11" cy="11" r="8"></circle>
                                        <line x1="21" y1="21" x2="16.65" y2="16.65">
                                        </line>
                                    </svg>
                                </li>
                            </ul>
                            </div>
                            <div className="parlay-icons">
                                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M908.1 353.1l-253.9-36.9L540.7 86.1c-3.1-6.3-8.2-11.4-14.5-14.5-15.8-7.8-35-1.3-42.9 14.5L369.8 316.2l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3a32.05 32.05 0 0 0 .6 45.3l183.7 179.1-43.4 252.9a31.95 31.95 0 0 0 46.4 33.7L512 754l227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2 17.4-3 29.1-19.5 26.1-36.9l-43.4-252.9 183.7-179.1c5-4.9 8.3-11.3 9.3-18.3 2.7-17.5-9.5-33.7-27-36.3z"></path></svg>
                                <svg onClick={() => setSearchActive(true)} stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" className="ms-auto" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="11" cy="11" r="8"></circle>
                                    <line x1="21" y1="21" x2="16.65" y2="16.65">
                                    </line>
                                </svg>
                            </div>
                        </div>
                    </form>
                </div>

                {searchActive &&
                    <div className="search-games-sec">
                        <form className="">
                            <div className="position-relative">
                                <input placeholder="Search platform, games, and more......" type="text" className="form-control" value="" />
                                <button type="button" className="bg-transparent border-0 text-dark position-absolute end-0 top-0 pt-0 fs-4 btn btn-primary" onClick={() => setSearchActive(false)}>
                                    <svg stroke="currentColor" fill="none" strokeWidth="0" viewBox="0 0 15 15" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M11.7816 4.03157C12.0062 3.80702 12.0062 3.44295 11.7816 3.2184C11.5571 2.99385 11.193 2.99385 10.9685 3.2184L7.50005 6.68682L4.03164 3.2184C3.80708 2.99385 3.44301 2.99385 3.21846 3.2184C2.99391 3.44295 2.99391 3.80702 3.21846 4.03157L6.68688 7.49999L3.21846 10.9684C2.99391 11.193 2.99391 11.557 3.21846 11.7816C3.44301 12.0061 3.80708 12.0061 4.03164 11.7816L7.50005 8.31316L10.9685 11.7816C11.193 12.0061 11.5571 12.0061 11.7816 11.7816C12.0062 11.557 12.0062 11.193 11.7816 10.9684L8.31322 7.49999L11.7816 4.03157Z" fill="currentColor"></path>
                                    </svg>
                                </button>
                            </div>
                            <div className="search-listing mt-sm-3 ">
                                <ul className="p-0 m-0">
                                    <div className="w-100 mb-3 match-list">

                                        {false ?
                                            <div className="no-event-outer">
                                                <div className="no-event">
                                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" color="lightgrey" className="mb-2" height="55" width="55" xmlns="http://www.w3.org/2000/svg" style={{ color: 'lightgrey;' }}>
                                                        <path d="M12 22c5.421 0 10-4.579 10-10h-2c0 4.337-3.663 8-8 8s-8-3.663-8-8c0-4.336 3.663-8 8-8V2C6.579 2 2 6.58 2 12c0 5.421 4.579 10 10 10z"></path>
                                                    </svg>
                                                    No Event
                                                </div>
                                            </div>

                                            :

                                            <ul className="p-0">
                                                <li>The Greatest Cards Show</li>
                                                <li>Triple Card Poker</li>
                                            </ul>
                                        }
                                    </div>

                                </ul>
                            </div>
                        </form>
                    </div>
                }

                <div className="game-menu">
                    <ul className="p-0 mb-0">
                        <li className={`${categoryActive === 'all' ? 'active' : ''}`} onClick={() => setcategoryActive('all')}>
                            <div>
                                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M868 160h-92v-40c0-4.4-3.6-8-8-8H256c-4.4 0-8 3.6-8 8v40h-92a44 44 0 0 0-44 44v148c0 81.7 60 149.6 138.2 162C265.6 630.2 359 721.8 476 734.5v105.2H280c-17.7 0-32 14.3-32 32V904c0 4.4 3.6 8 8 8h512c4.4 0 8-3.6 8-8v-32.3c0-17.7-14.3-32-32-32H548V734.5C665 721.8 758.4 630.2 773.8 514 852 501.6 912 433.7 912 352V204a44 44 0 0 0-44-44zM248 439.6c-37.1-11.9-64-46.7-64-87.6V232h64v207.6zM840 352c0 41-26.9 75.8-64 87.6V232h64v120z"></path>
                                </svg>
                            </div>
                            All
                        </li>
                        <li className={`${categoryActive === 'cricket' ? 'active' : ''}`} onClick={() => setcategoryActive('cricket')}>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" version="1.0" viewBox="0 0 93.000000 96.000000" preserveAspectRatio="xMidYMid meet">
                                    <g transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)" stroke="currentColor" fill="currentColor">
                                        <path d="M352 913 c-13 -3 -22 -9 -20 -15 2 -5 116 -147 253 -314 169 -206 251 -300 257 -291 5 6 18 43 29 81 68 219 -58 452 -287 530 -45 15 -173 20 -232 9z" />
                                        <path d="M208 846 c-10 -7 -18 -16 -18 -20 0 -4 122 -155 271 -337 l271 -330 24 22 23 21 -79 97 c-379 462 -456 555 -465 557 -5 2 -18 -3 -27 -10z" />
                                        <path d="M80 698 c-40 -71 -54 -138 -48 -239 7 -137 71 -249 185 -325 85 -56 161 -77 263 -71 82 4 174 31 168 48 -6 18 -520 639 -528 639 -5 0 -23 -24 -40 -52z" />
                                    </g>
                                </svg>
                            </div>
                            Cricket
                        </li>
                        <li className={`${categoryActive === 'soccer' ? 'active' : ''}`} onClick={() => setcategoryActive('soccer')}>
                            <div>
                                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M255.03 33.813c-1.834-.007-3.664-.007-5.5.03-6.73.14-13.462.605-20.155 1.344.333.166.544.32.47.438L204.78 75.063l73.907 49.437-.125.188 70.625.28L371 79.282 342.844 52c-15.866-6.796-32.493-11.776-49.47-14.78-12.65-2.24-25.497-3.36-38.343-3.407zM190.907 88.25l-73.656 36.78-13.813 98.407 51.344 33.657 94.345-43.438 14.875-76.5-73.094-48.906zm196.344.344l-21.25 44.5 36.75 72.72 62.063 38.905 11.312-21.282c.225.143.45.403.656.75-.77-4.954-1.71-9.893-2.81-14.782-6.446-28.59-18.59-55.962-35.5-79.97-9.07-12.872-19.526-24.778-31.095-35.5l-20.125-5.342zm-302.656 23c-6.906 8.045-13.257 16.56-18.938 25.5-15.676 24.664-26.44 52.494-31.437 81.312C31.783 232.446 30.714 246.73 31 261l20.25 5.094 33.03-40.5L98.75 122.53l-14.156-10.936zm312.719 112.844l-55.813 44.75-3.47 101.093 39.626 21.126 77.188-49.594 4.406-78.75-.094.157-61.844-38.783zm-140.844 6.406l-94.033 43.312-1.218 76.625 89.155 57.376 68.938-36.437 3.437-101.75-66.28-39.126zm-224.22 49.75c.91 8.436 2.29 16.816 4.156 25.094 6.445 28.59 18.62 55.96 35.532 79.968 3.873 5.5 8.02 10.805 12.374 15.938l-9.374-48.156.124-.032-27.03-68.844-15.782-3.968zm117.188 84.844l-51.532 8.156 10.125 52.094c8.577 7.49 17.707 14.332 27.314 20.437 14.612 9.287 30.332 16.88 46.687 22.594l62.626-13.69-4.344-31.124-90.875-58.47zm302.437.5l-64.22 41.25-42 47.375 4.408 6.156c12.027-5.545 23.57-12.144 34.406-19.72 23.97-16.76 44.604-38.304 60.28-62.97 2.51-3.947 4.87-7.99 7.125-12.092zm-122.78 97.656l-79.94 9.625-25.968 5.655c26.993 4 54.717 3.044 81.313-2.813 9.412-2.072 18.684-4.79 27.75-8.062l-3.156-4.406z"></path>
                                </svg>
                            </div>
                            Soccer
                        </li>
                        <li className={`${categoryActive === 'tennis' ? 'active' : ''}`} onClick={() => setcategoryActive('tennis')}>
                            <div>
                                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M246.938 35.188c-25.875 1.07-51.58 6.686-75.5 16.593-26.714 11.065-51.274 27.462-71.72 47.907-20.445 20.446-36.84 45.005-47.906 71.72-9.8 23.662-15.405 49.065-16.562 74.655 74.49-11.465 126.867-33.367 160.656-67.156 33.846-33.845 50.15-80.167 51.03-143.72zm18 0c-.91 66.75-18.61 118.764-56.313 156.468-38.05 38.05-95.324 60.96-173.438 72.625 1 26.133 6.65 52.13 16.657 76.283 11.067 26.715 27.458 51.24 47.906 71.687 20.448 20.448 45.003 36.87 71.72 47.938 24.152 10.005 50.122 15.63 76.25 16.625 11.665-78.115 34.575-135.39 72.624-173.438 37.704-37.704 89.72-55.402 156.47-56.313-1.06-25.916-6.67-51.663-16.595-75.625-11.068-26.715-27.49-51.27-47.94-71.718-20.447-20.45-44.97-36.84-71.686-47.907-23.96-9.927-49.735-15.56-75.656-16.626zm211.187 229.875c-63.202.987-109.34 17.307-143.063 51.03-33.793 33.795-55.662 86.15-67.125 160.657 25.596-1.157 51.026-6.762 74.688-16.563 26.714-11.064 51.242-27.46 71.688-47.906 20.445-20.444 36.842-44.972 47.906-71.686 9.906-23.922 15.522-49.652 16.592-75.53-.228.002-.46-.005-.687-.002z"></path>
                                </svg>
                            </div>
                            Tennis
                        </li>
                        <li className={`${categoryActive === 'Virtual' ? 'active' : ''}`} onClick={() => setcategoryActive('Virtual')}>
                            <div>
                                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M255.03 33.813c-1.834-.007-3.664-.007-5.5.03-6.73.14-13.462.605-20.155 1.344.333.166.544.32.47.438L204.78 75.063l73.907 49.437-.125.188 70.625.28L371 79.282 342.844 52c-15.866-6.796-32.493-11.776-49.47-14.78-12.65-2.24-25.497-3.36-38.343-3.407zM190.907 88.25l-73.656 36.78-13.813 98.407 51.344 33.657 94.345-43.438 14.875-76.5-73.094-48.906zm196.344.344l-21.25 44.5 36.75 72.72 62.063 38.905 11.312-21.282c.225.143.45.403.656.75-.77-4.954-1.71-9.893-2.81-14.782-6.446-28.59-18.59-55.962-35.5-79.97-9.07-12.872-19.526-24.778-31.095-35.5l-20.125-5.342zm-302.656 23c-6.906 8.045-13.257 16.56-18.938 25.5-15.676 24.664-26.44 52.494-31.437 81.312C31.783 232.446 30.714 246.73 31 261l20.25 5.094 33.03-40.5L98.75 122.53l-14.156-10.936zm312.719 112.844l-55.813 44.75-3.47 101.093 39.626 21.126 77.188-49.594 4.406-78.75-.094.157-61.844-38.783zm-140.844 6.406l-94.033 43.312-1.218 76.625 89.155 57.376 68.938-36.437 3.437-101.75-66.28-39.126zm-224.22 49.75c.91 8.436 2.29 16.816 4.156 25.094 6.445 28.59 18.62 55.96 35.532 79.968 3.873 5.5 8.02 10.805 12.374 15.938l-9.374-48.156.124-.032-27.03-68.844-15.782-3.968zm117.188 84.844l-51.532 8.156 10.125 52.094c8.577 7.49 17.707 14.332 27.314 20.437 14.612 9.287 30.332 16.88 46.687 22.594l62.626-13.69-4.344-31.124-90.875-58.47zm302.437.5l-64.22 41.25-42 47.375 4.408 6.156c12.027-5.545 23.57-12.144 34.406-19.72 23.97-16.76 44.604-38.304 60.28-62.97 2.51-3.947 4.87-7.99 7.125-12.092zm-122.78 97.656l-79.94 9.625-25.968 5.655c26.993 4 54.717 3.044 81.313-2.813 9.412-2.072 18.684-4.79 27.75-8.062l-3.156-4.406z"></path>
                                </svg>
                            </div>
                            Virtual
                        </li>
                    </ul>
                </div>


                <div className="all-complete">
                    <div className="dropdown">
                        <button type="button" id="abc" aria-expanded="false" className={`dropdown-toggle btn btn-primary ${openDropdown ? 'active' : ''}`} onClick={() => setOpenDropdown(!openDropdown)}>
                            <div>
                                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" className="me-1" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7 20h2V8h3L8 4 4 8h3zm13-4h-3V4h-2v12h-3l4 4z"></path>
                                </svg>
                                by Competition
                            </div>
                        </button>
                        {openDropdown &&
                            <div x-placement="bottom-start" aria-labelledby="abc" className="dropdown-menu" data-popper-reference-hidden="false" data-popper-escaped="false" data-popper-placement="bottom-start"
                            // style="position: absolute; inset: 0px auto auto 0px; transform: translate3d(0px, 40.5px, 0px);"
                            >
                                <a data-rr-ui-dropdown-item="" className="dropdown-item" role="button" >by Time</a>
                            </div>
                        }
                    </div>
                </div>


                {isLoadingMain ?
                    <div className='loader_top'>
                        <Loader />
                    </div>
                    :
                    <>

                        {/* for cricket */}
                        {(categoryActive === 'all' || categoryActive === 'cricket') ?
                            (isLoading || pageData?.cricket?.length > 0) ?
                                <>
                                    <div className='bg-skylight matchlist'>
                                        <div>
                                            <div className="matchlist-heading">
                                                <h6 className="sports-heading">Cricket</h6>
                                                <div className={`all-complete-all ${categoryState.cricket ? 'active' : ''}`} onClick={() => handleClick('cricket')}>
                                                    <span className="mr-1" >ALL</span>
                                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill="none" d="M0 0h24v24H0z"></path>
                                                        <path d="M6 17.59L7.41 19 12 14.42 16.59 19 18 17.59l-6-6z"></path>
                                                        <path d="M6 11l1.41 1.41L12 7.83l4.59 4.58L18 11l-6-6z"></path>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>

                                        {pageData && allTurnaments && !isLoading && pageData.cricket.length > 0 ?
                                            <>
                                                {allTurnaments?.cricket?.sort(customSort).map((sport, i) => {
                                                    return (
                                                        <div className='bookmaker-wrp'>
                                                            <div className="bookmaker p-sm-2 sport-accordian">
                                                                <div className="accordion">
                                                                    <div className="accordion-item">
                                                                        {console.log("sports", sport)}
                                                                        <h2 className={`accordion-header ${(activeAccordions.includes(`cricket_${sport}`) || activeAccordions.includes('cricket_all')) ? 'active' : ''}`}
                                                                            onClick={() => toggleAccordion(`cricket_${sport}`)}
                                                                        >
                                                                            <button type="button" aria-expanded="true" className="accordion-button">
                                                                                <span className="sport-counter bg-yellow ">{pageData.cricket.filter((_) => _.Turnament === sport).length}</span> {sport ? sport : 'other'}
                                                                            </button>
                                                                        </h2>
                                                                        {pageData.cricket.filter((_) => _.Turnament === sport).map((item, i) => {
                                                                            return (
                                                                                <div onClick={() => {
                                                                                    localStorage.setItem('sportsName', item.eventName);
                                                                                    localStorage.setItem('sportsData', JSON.stringify(item))
                                                                                    navigate(`/multimarket/${item.gameId}/${item.marketId}`);
                                                                                }} className={`accordion-collapse collapse ${(activeAccordions.includes(`cricket_${sport}`) || activeAccordions.includes('cricket_all')) ? 'slide-down' : 'slide-up'}`} >
                                                                                    <div className="p-0 py-1 px-sm-3 px-2 accordion-body">
                                                                                        <div className="sport-accord-body">
                                                                                            <div className="">
                                                                                                <div className="sport-accord-body-wrp">
                                                                                                    {!parlyActive && <div className="fave bg-star-sprit-black position-absolute start-0" ></div>}
                                                                                                    <div className={`${!parlyActive ? 'ps-4' : ''}`}>
                                                                                                        <div className="icon-sports d-flex align-items-center">
                                                                                                            <div>
                                                                                                                {item.inPlay && <img src={YoutubeImage} alt="" />}
                                                                                                                {(item.f && !parlyActive) && <img src={FImage} alt="" />}
                                                                                                                {(item.m1 && !parlyActive) && <img src={BImage} alt="" />}
                                                                                                                {!parlyActive && <img src={SImage} alt="" />}
                                                                                                            </div>
                                                                                                            {item.inPlay && <span className="in-play ms-1 in-play">In-play</span>}
                                                                                                        </div>
                                                                                                        <span className='match-name' onClick={() =>{
                                                                                                          localStorage.setItem('sportsName', item.eventName);  
                                                                                                          localStorage.setItem('sportsData', JSON.stringify(item))
                                                                                                          navigate(`/multimarket/${item.gameId}/${item.marketId}`);
                                                                                                        } }>{item.eventName}</span>
                                                                                                    </div>
                                                                                                    <div className="d-flex align-items-center">
                                                                                                        <div className="counter">0 - 0</div>
                                                                                                        <button type="button" className="d-flex align-items-center bg-transparent text-dark border-0 fs-4 px-2 pt-0 pe-0">
                                                                                                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 256 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                                                                                <path d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path>
                                                                                                            </svg>
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </div>
                                                                                                {console.log("item", item)}
                                                                                                {!parlyActive &&
                                                                                                    <div className="sports-team-table">
                                                                                                        <div className="match-odd-table bg-white p-3 px-0 py-1">
                                                                                                            <div className="d-flex back-lay-head justify-content-end ms-auto pb-1 bg-yellow w-100">
                                                                                                                <div className="half-table" >
                                                                                                                    <h4 className="text-left">Match Odds</h4>
                                                                                                                    {/* <strong className="px-2">27,111,747.02</strong> */}
                                                                                                                </div>
                                                                                                                <span className="text-center half-table-text" style={{ width: '64px' }}>Back</span>
                                                                                                                <span className="text-center half-table-text" style={{ width: '64px' }}>Lay</span>
                                                                                                            </div>
                                                                                                            <ul className="mb-2 rounded position-relative p-0">
                                                                                                                <li>
                                                                                                                    <h2>{item?.eventName?.split(' v ')[0]} </h2>
                                                                                                                    <div className="lay-back-table ">
                                                                                                                        <div id="back_odds_0" className="back">
                                                                                                                            <span className="">
                                                                                                                                <strong>{item.back1}</strong>
                                                                                                                                <span className="d-block">{Math.floor(Math.random() * 10)}</span>
                                                                                                                            </span>
                                                                                                                        </div>
                                                                                                                        <div title="32-152.14" id="lay_odds_0" className="lay  ">
                                                                                                                            <span className="">
                                                                                                                                <strong>{item.lay1}</strong>
                                                                                                                                <span className="d-block">{Math.floor(Math.random() * 10)}</span>
                                                                                                                            </span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </li>
                                                                                                                <li>
                                                                                                                    <h2>{item?.eventName?.split(' v ')[1]} </h2>
                                                                                                                    <div className="lay-back-table ">
                                                                                                                        <div title="1.03-87868.13" id="back_odds_0" className="back spark-back ">
                                                                                                                            <span className="">
                                                                                                                                <strong>{item.back2}</strong>
                                                                                                                                <span className="d-block">{Math.floor(Math.random() * 10)}</span>
                                                                                                                            </span>
                                                                                                                        </div>
                                                                                                                        <div id="lay_odds_0" className="lay spark-lay ">
                                                                                                                            <span className="">
                                                                                                                                <strong>{item.lay2}</strong>
                                                                                                                                <span className="d-block">{Math.floor(Math.random() * 10)}</span>
                                                                                                                            </span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </li>
                                                                                                            </ul>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                            )
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div >
                                                    )
                                                })}
                                            </>
                                            : isLoading ? <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><img style={{ width: 100 }} src={LoaderImage} alt="" /></div> : <h2 className='no-data'>No data</h2>
                                        }
                                    </div>
                                </>
                                :
                                categoryActive !== 'all' && <h2 className='no-data'>No data</h2>
                            :
                            ''
                        }

                        {/* for soccer */}
                        {(categoryActive === 'all' || categoryActive === 'soccer') ?
                            (isLoading || pageData?.soccer?.length > 0) ?
                                <>
                                    <div className='bg-skylight matchlist'>
                                        <div>
                                            <div className="matchlist-heading">
                                                <h6 className="sports-heading">Soccer</h6>
                                                <div className={`all-complete-all ${categoryState.soccer ? 'active' : ''}`} onClick={() => handleClick('soccer')}>
                                                    <span className="mr-1">ALL</span>
                                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill="none" d="M0 0h24v24H0z"></path>
                                                        <path d="M6 17.59L7.41 19 12 14.42 16.59 19 18 17.59l-6-6z"></path>
                                                        <path d="M6 11l1.41 1.41L12 7.83l4.59 4.58L18 11l-6-6z"></path>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>

                                        {pageData && allTurnaments && !isLoading && pageData.soccer.length > 0 ?
                                            <>
                                                {allTurnaments?.soccer?.sort(customSort).map((sport, i) => {
                                                    return (
                                                        <div className='bookmaker-wrp'>
                                                            <div className="bookmaker p-sm-2 sport-accordian">
                                                                <div className="accordion">
                                                                    <div className="accordion-item">
                                                                        <h2 className={`accordion-header ${(activeAccordions.includes(`soccer_${sport}`) || activeAccordions.includes('soccer_all')) ? 'active' : ''}`}
                                                                            onClick={() => toggleAccordion(`soccer_${sport}`)}
                                                                        >
                                                                            <button type="button" aria-expanded="true" className="accordion-button">
                                                                                <span className="sport-counter bg-yellow ">{pageData.soccer.filter((_) => _.Turnament === sport).length}</span> {sport ? sport : 'other'}
                                                                            </button>
                                                                        </h2>
                                                                        {pageData.soccer.filter((_) => _.Turnament === sport).map((item, i) => {
                                                                            return (
                                                                                <div onClick={() => {
                                                                                    localStorage.setItem('sportsName', item.eventName);
                                                                                    localStorage.setItem('sportsData', JSON.stringify(item))
                                                                                    navigate(`/multimarket/${item.gameId}/${item.marketId}`);
                                                                                }} className={`accordion-collapse collapse ${(activeAccordions.includes(`soccer_${sport}`) || activeAccordions.includes('soccer_all')) ? 'slide-down' : 'slide-up'}`} >
                                                                                    <div className="p-0 py-1 px-sm-3 px-2 accordion-body">
                                                                                        <div className="sport-accord-body">
                                                                                            <div className="">
                                                                                                <div className="sport-accord-body-wrp">
                                                                                                    {!parlyActive && <div className="fave bg-star-sprit-black position-absolute start-0" ></div>}
                                                                                                    <div className={`${!parlyActive ? 'ps-4' : ''}`}>
                                                                                                        <div className="icon-sports d-flex align-items-center">
                                                                                                            <div>
                                                                                                                {item.inPlay && <img src={YoutubeImage} alt="" />}
                                                                                                                {(item.f && !parlyActive) && <img src={FImage} alt="" />}
                                                                                                                {(item.m1 && !parlyActive) && <img src={BImage} alt="" />}
                                                                                                                {!parlyActive && <img src={SImage} alt="" />}
                                                                                                            </div>
                                                                                                            {item.inPlay && <span className="in-play ms-1 in-play">In-play</span>}
                                                                                                        </div>
                                                                                                        <span className='match-name' onClick={() => {
                                                                                                            localStorage.setItem('sportsName', item.eventName);
                                                                                                            localStorage.setItem('sportsData', JSON.stringify(item))
                                                                                                            navigate(`/multimarket/${item.gameId}/${item.marketId}`);
                                                                                                        } }>{item.eventName}</span>
                                                                                                    </div>
                                                                                                    <div className="d-flex align-items-center">
                                                                                                        <div className="counter">0 - 0</div>
                                                                                                        <button type="button" className="d-flex align-items-center bg-transparent text-dark border-0 fs-4 px-2 pt-0 pe-0">
                                                                                                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 256 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                                                                                <path d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path>
                                                                                                            </svg>
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </div>
                                                                                                {!parlyActive &&
                                                                                                    <div className="sports-team-table">
                                                                                                        <div className="match-odd-table bg-white p-3 px-0 py-1">
                                                                                                            <div className="d-flex back-lay-head justify-content-end ms-auto pb-1 bg-yellow w-100">
                                                                                                                <div className="half-table" >
                                                                                                                    <h4 className="text-left">Match Odds</h4>
                                                                                                                    {/* <strong className="px-2">27,111,747.02</strong> */}
                                                                                                                </div>
                                                                                                                <span className="text-center half-table-text" style={{ width: '64px' }}>Back</span>
                                                                                                                <span className="text-center half-table-text" style={{ width: '64px' }}>Lay</span>
                                                                                                            </div>
                                                                                                            <ul className="mb-2 rounded position-relative p-0">
                                                                                                                <li>
                                                                                                                    <h2>{item?.eventName?.split(' v ')[0]} </h2>
                                                                                                                    <div className="lay-back-table ">
                                                                                                                        <div id="back_odds_0" className="back">
                                                                                                                            <span className="">
                                                                                                                                <strong>{item.back1}</strong>
                                                                                                                                <span className="d-block">{Math.floor(Math.random() * 10)}</span>
                                                                                                                            </span>
                                                                                                                        </div>
                                                                                                                        <div title="32-152.14" id="lay_odds_0" className="lay  ">
                                                                                                                            <span className="">
                                                                                                                                <strong>{item.lay1}</strong>
                                                                                                                                <span className="d-block">{Math.floor(Math.random() * 10)}</span>
                                                                                                                            </span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </li>
                                                                                                                <li>
                                                                                                                    <h2>{item?.eventName?.split(' v ')[1]} </h2>
                                                                                                                    <div className="lay-back-table ">
                                                                                                                        <div title="1.03-87868.13" id="back_odds_0" className="back spark-back ">
                                                                                                                            <span className="">
                                                                                                                                <strong>{item.back2}</strong>
                                                                                                                                <span className="d-block">{Math.floor(Math.random() * 10)}</span>
                                                                                                                            </span>
                                                                                                                        </div>
                                                                                                                        <div id="lay_odds_0" className="lay spark-lay ">
                                                                                                                            <span className="">
                                                                                                                                <strong>{item.lay2}</strong>
                                                                                                                                <span className="d-block">{Math.floor(Math.random() * 10)}</span>
                                                                                                                            </span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </li>
                                                                                                            </ul>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                            )
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div >
                                                    )
                                                })}
                                            </>
                                            : isLoading ? <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><img style={{ width: 100 }} src={LoaderImage} alt="" /></div> : <h2 className='no-data'>No data</h2>
                                        }
                                    </div >
                                </>
                                :
                                categoryActive !== 'all' && <h2 className='no-data'>No data</h2>
                            :
                            ''
                        }

                        {/* for tenis */}
                        {(categoryActive === 'all' || categoryActive === 'tennis') ?
                            (isLoading || pageData?.tennis?.length > 0) ?
                                <>
                                    <div className='bg-skylight matchlist'>
                                        <div>
                                            <div className="matchlist-heading">
                                                <h6 className="sports-heading">Tennis</h6>
                                                <div className={`all-complete-all ${categoryState.tennis ? 'active' : ''}`} onClick={() => handleClick('tennis')}>
                                                    <span className="mr-1">ALL</span>
                                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill="none" d="M0 0h24v24H0z"></path>
                                                        <path d="M6 17.59L7.41 19 12 14.42 16.59 19 18 17.59l-6-6z"></path>
                                                        <path d="M6 11l1.41 1.41L12 7.83l4.59 4.58L18 11l-6-6z"></path>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>

                                        {pageData && allTurnaments && !isLoading && pageData.tennis.length > 0 ?
                                            <>
                                                {allTurnaments?.tennis?.sort(customSort).map((sport, i) => {
                                                    return (
                                                        <div className='bookmaker-wrp'>
                                                            <div className="bookmaker p-sm-2 sport-accordian">
                                                                <div className="accordion">
                                                                    <div className="accordion-item">
                                                                        <h2 className={`accordion-header ${(activeAccordions.includes(`tennis_${sport}`) || activeAccordions.includes('tennis_all')) ? 'active' : ''}`}
                                                                            onClick={() => toggleAccordion(`tennis_${sport}`)}
                                                                        >
                                                                            <button type="button" aria-expanded="true" className="accordion-button">
                                                                                <span className="sport-counter bg-yellow ">{pageData.tennis.filter((_) => _.Turnament === sport).length}</span> {sport ? sport : 'other'}
                                                                            </button>
                                                                        </h2>
                                                                        {pageData.tennis.filter((_) => _.Turnament === sport).map((item, i) => {
                                                                            return (
                                                                                <div onClick={() => {
                                                                                    localStorage.setItem('sportsName', item.eventName);  
                                                                                    localStorage.setItem('sportsData', JSON.stringify(item)) 
                                                                                    navigate(`/multimarket/${item.gameId}/${item.marketId}`);
                                                                                }} className={`accordion-collapse collapse ${(activeAccordions.includes(`tennis_${sport}`) || activeAccordions.includes('tennis_all')) ? 'slide-down' : 'slide-up'}`} >
                                                                                    <div className="p-0 py-1 px-sm-3 px-2 accordion-body">
                                                                                        <div className="sport-accord-body">
                                                                                            <div className="">
                                                                                                <div className="sport-accord-body-wrp">
                                                                                                    {!parlyActive && <div className="fave bg-star-sprit-black position-absolute start-0" ></div>}
                                                                                                    <div className={`${!parlyActive ? 'ps-4' : ''}`}>
                                                                                                        <div className="icon-sports d-flex align-items-center">
                                                                                                            <div>
                                                                                                                {item.inPlay && <img src={YoutubeImage} alt="" />}
                                                                                                                {(item.f && !parlyActive) && <img src={FImage} alt="" />}
                                                                                                                {(item.m1 && !parlyActive) && <img src={BImage} alt="" />}
                                                                                                                {!parlyActive && <img src={SImage} alt="" />}
                                                                                                            </div>
                                                                                                            {item.inPlay && <span className="in-play ms-1 in-play">In-play</span>}
                                                                                                        </div>
                                                                                                        <span className='match-name' onClick={() =>{
                                                                                                          localStorage.setItem('sportsName', item.eventName);  
                                                                                                          localStorage.setItem('sportsData', JSON.stringify(item))
                                                                                                          navigate(`/multimarket/${item.gameId}/${item.marketId}`)
                                                                                                        } }>{item.eventName}</span>
                                                                                                    </div>
                                                                                                    <div className="d-flex align-items-center">
                                                                                                        <div className="counter">0 - 0</div>
                                                                                                        <button type="button" className="d-flex align-items-center bg-transparent text-dark border-0 fs-4 px-2 pt-0 pe-0">
                                                                                                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 256 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                                                                                <path d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path>
                                                                                                            </svg>
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </div>
                                                                                                {!parlyActive &&
                                                                                                    <div className="sports-team-table">
                                                                                                        <div className="match-odd-table bg-white p-3 px-0 py-1">
                                                                                                            <div className="d-flex back-lay-head justify-content-end ms-auto pb-1 bg-yellow w-100">
                                                                                                                <div className="half-table" >
                                                                                                                    <h4 className="text-left">Match Odds</h4>
                                                                                                                    {/* <strong className="px-2">27,111,747.02</strong> */}
                                                                                                                </div>
                                                                                                                <span className="text-center half-table-text" style={{ width: '64px' }}>Back</span>
                                                                                                                <span className="text-center half-table-text" style={{ width: '64px' }}>Lay</span>
                                                                                                            </div>
                                                                                                            <ul className="mb-2 rounded position-relative p-0">
                                                                                                                <li>
                                                                                                                    <h2>{item?.eventName?.split(' v ')[0]} </h2>
                                                                                                                    <div className="lay-back-table ">
                                                                                                                        <div id="back_odds_0" className="back">
                                                                                                                            <span className="">
                                                                                                                                <strong>{item.back1}</strong>
                                                                                                                                <span className="d-block">{Math.floor(Math.random() * 10)}</span>
                                                                                                                            </span>
                                                                                                                        </div>
                                                                                                                        <div title="32-152.14" id="lay_odds_0" className="lay  ">
                                                                                                                            <span className="">
                                                                                                                                <strong>{item.lay1}</strong>
                                                                                                                                <span className="d-block">{Math.floor(Math.random() * 10)}</span>
                                                                                                                            </span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </li>
                                                                                                                <li>
                                                                                                                    <h2>{item?.eventName?.split(' v ')[1]} </h2>
                                                                                                                    <div className="lay-back-table ">
                                                                                                                        <div title="1.03-87868.13" id="back_odds_0" className="back spark-back ">
                                                                                                                            <span className="">
                                                                                                                                <strong>{item.back2}</strong>
                                                                                                                                <span className="d-block">{Math.floor(Math.random() * 10)}</span>
                                                                                                                            </span>
                                                                                                                        </div>
                                                                                                                        <div id="lay_odds_0" className="lay spark-lay ">
                                                                                                                            <span className="">
                                                                                                                                <strong>{item.lay2}</strong>
                                                                                                                                <span className="d-block">{Math.floor(Math.random() * 10)}</span>
                                                                                                                            </span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </li>
                                                                                                            </ul>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                            )
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div >
                                                    )
                                                })}
                                            </>
                                            : isLoading ? <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><img style={{ width: 100 }} src={LoaderImage} alt="" /></div> : <h2 className='no-data'>No data</h2>
                                        }
                                    </div >
                                </>
                                :
                                categoryActive !== 'all' && <h2 className='no-data'>No data</h2>
                            :
                            ''
                        }
                    </>
                }


                <Footer />
            </div >
            <BottomFooter />
        </>
    )
}


export default Sports



