import React, { useState } from "react";
import { useSelector } from "react-redux";
// import Cookies from "universal-cookie";
// const cookies = new Cookies();

const NewsLine = () => {
  const DD = useSelector((e: any) => e.domainDetails);
  // const authToken = cookies.get("skyTokenFront");
  const [popUp, setPopUp] = useState<boolean>(false)
  return (
    <>
     
      <div className="marquee-box" style={{ display: "flex" }}>
        <h4>
          <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M842 454c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8 0 140.3-113.7 254-254 254S258 594.3 258 454c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8 0 168.7 126.6 307.9 290 327.6V884H326.7c-13.7 0-24.7 14.3-24.7 32v36c0 4.4 2.8 8 6.2 8h407.6c3.4 0 6.2-3.6 6.2-8v-36c0-17.7-11-32-24.7-32H548V782.1c165.3-18 294-158 294-328.1zM512 624c93.9 0 170-75.2 170-168V232c0-92.8-76.1-168-170-168s-170 75.2-170 168v224c0 92.8 76.1 168 170 168zm-94-392c0-50.6 41.9-92 94-92s94 41.4 94 92v224c0 50.6-41.9 92-94 92s-94-41.4-94-92V232z"></path></svg>
        </h4>
        <div className="marquee">
          <div className="js-marquee-wrapper">
            <a onClick={()=> setPopUp(true)}>
              {DD?.maintenanceMessage
                ? DD?.maintenanceMessage
                : DD?.userMessage}
            </a>
            {/* <a><span>30 Oct 2022</span>Match :- Melbourne Renegades WBBL v Sydney Sixers WBBL .. Market :- SYSW 20 Over Runs '184 - 186' ( IST 10:32:44 - 10:32:55) Bets Voided Because of Wrong Commentary ... Sorry for the Inconvenience Caused</a><a><span>30 Oct 2022</span>Event :- Bangladesh v Zimbabwe ... Market :- Bookmaker ... Selection :- Bangladesh ' 116 - 117.5 ' ( IST 11:29:30 ) Bets Voided Because Wrong Odds Offered By Mistake ... Sorry for the Inconvenience Caused</a> */}

          </div>
        </div>
      </div>
      { popUp && 
                <div className="announcement-modal modal-show">
                    <div className="modal-content">
                        <div className="modal-header d-flex justify-content-between">
                            <h4 className="text-center">Announcement</h4>
                            <div className="close" onClick={() => setPopUp(false)}></div>
                        </div>
                        <div className="modal-body">
                            <span className="ver-tag">
                                6.17.2024
                            </span>
                            <p>Sri Lanka V Netherlands</p>
                            <p>3 Over Run NED	</p>
                            <p>30-100/31-100/29-100/30-100/30-110/30-90 Rate-Bets-Will-Be-Voided-Due-To-No-Ball(07:56:36 TO 07:57:02 IST)</p>
                            <p>4 Over Run NED	</p>
                            <p>10 Over Run NED	</p>
                            <p>81-100/83-100 Rate-Bets-Will-Be-Voided-Due-To-No-Ball(07:56:36 TO 07:57:02 IST)</p>
                            <div className="line"></div>
                            <span className="ver-tag">
                                6.17.2024
                            </span>
                            <p>Sri Lanka V Netherlands</p>
                            <p>3 Over Run NED	</p>
                            <p>30-100/31-100/29-100/30-100/30-110/30-90 Rate-Bets-Will-Be-Voided-Due-To-No-Ball(07:56:36 TO 07:57:02 IST)</p>
                            <p>4 Over Run NED	</p>
                            <p>10 Over Run NED	</p>
                            <p>81-100/83-100 Rate-Bets-Will-Be-Voided-Due-To-No-Ball(07:56:36 TO 07:57:02 IST)</p>
                            <div className="line"></div>
                        </div>
                    </div>
                </div>
            }
      {/* ) : (
        ""
      )} */}
    </>
  );
};

export default NewsLine;
